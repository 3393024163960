import React, { Component } from "react";
import { Query } from '@syncfusion/ej2-data';
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import i18n from '../../../utilities/Internationalization';

export default class OilmatCompanyData extends Component {
    constructor(props) {
        super(props);

        this.queryText = '';

        this.onPostalCodeFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Value', 'startswith', e.text, true) : query;
            this.queryText = e.text;
            e.updateData(props.lookup.postalCodes, query)
        }

        this.onCityFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            this.queryText = e.text;
            e.updateData(props.lookup.cities, query)
        }

        this.onRegionFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            this.queryText = e.text;
            e.updateData(props.lookup.regions, query)
        }

        this.onCountryFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            e.updateData(props.lookup.countries, query)
        }
    }

    onChange = (args) => {
        this.props.onChange(args.event.target.name, args.value);
    }
    
    onPostalCodeChange = (args) => {
        if (args.isInteracted) {
            this.props.onChange("PostalCodeId", args.value);
            this.props.onChange("PostalCodeName", args.itemData.Value);

            if (args.itemData.CityId) {
                this.props.onChange("PostalCodeNew", null);
                this.props.onChange("CityId", args.itemData.CityId);
    
                const city = this.props.lookup.cities.find(city => city.Id === args.itemData.CityId);
                this.props.onChange("CityName", city.Name);
                this.props.onChange("RegionId", city.RegionId);
    
                const region = this.props.lookup.regions.find(region => region.Id === city.RegionId);
                this.props.onChange("RegionName", region.Name);
                this.props.onChange("CountryId", region.CountryId);
    
                const country = this.props.lookup.countries.find(country => country.Id === region.CountryId);
                this.props.onChange("CountryName", country.Name);
            }
        }
    }

    onCityChange = (args) => {
        if (args.isInteracted) {
            this.props.onChange("CityId", args.value);
            this.props.onChange("CityName", args.itemData.Name);

            if (args.itemData.RegionId) {
                this.props.onChange("CityNew", null);
                this.props.onChange("RegionId", args.itemData.RegionId);

                const region = this.props.lookup.regions.find(region => region.Id === args.itemData.RegionId);
                this.props.onChange("RegionName", region.Name);
                this.props.onChange("CountryId", region.CountryId);
    
                const country = this.props.lookup.countries.find(country => country.Id === region.CountryId);
                this.props.onChange("CountryName", country.Name);
            }
        }
    }

    onDropdownChange = (name, args) => {
        if (args.isInteracted) {
            this.props.onChange(name, args.value);
            
            if (name === "RegionId") {
                this.props.onChange("RegionName", args.itemData.Name);

                if (args.itemData.CountryId) {
                    this.props.onChange("RegionNew", null);
                    this.props.onChange("CountryId", args.itemData.CountryId);
    
                    const country = this.props.lookup.countries.find(country => country.Id === args.itemData.CountryId);
                    this.props.onChange("CountryName", country.Name);
                }
            }
            else if (name === "CountryId") {
                this.props.onChange("CountryName", args.itemData.Name);
            }
        }
    }

    addMissingItem = (name) => {
        this.props.onAddMissingItem(name, this.queryText, (newValue) => {
            switch (name) {
                case 'PostalCodeNew':
                    this.postalCodeRef.hidePopup();
                    this.postalCodeRef.value = newValue;
                    this.postalCodeRef.refresh();
                    break;
                case 'CityNew':
                    this.cityRef.hidePopup();
                    this.cityRef.value = newValue;
                    this.cityRef.refresh();
                    break;
                case 'RegionNew':
                    this.regionRef.hidePopup();
                    this.regionRef.value = newValue;
                    this.regionRef.refresh();
                    break;
                default:
                    break;
            }
        });
    }

    noRecordTemplate = (name) => {
        const { t } = this.props;
        return <span>
            {t('NoRecordFound')} <span className="clickable c-link" onClick={() => this.addMissingItem(name)}>{`${t('Add')} ${this.queryText}?`}</span>
        </span>;
    }

    render() {
        const { data, lookup, validation, t } = this.props;

        return <div>
            <div className="title">{t('TitleCompanyData')}</div>
            <div className="info-text m-bottom-15">{t('RequiredFieldsInfoText')}</div>
            
            <div className="m-bottom-10">
                <TextBoxComponent
                    name="CompanyName"
                    value={data.CompanyName}
                    placeholder={t('LabelCompanyName') + "*"}
                    input={this.onChange}
                    floatLabelType="Auto"
                    cssClass={`${validation.hasError && validation.errors.some(e => e.id === "CompanyName") ? "e-error" : ""}`}
                />
                { validation.hasError && validation.errors.some(e => (e.id === "CompanyName") && (e.message !== null || e.message !== undefined)) 
                    && <span className="error">{validation.errors.find(e => e.id === "CompanyName").message}</span> }
            </div>
            <div className="m-bottom-10">
                <TextBoxComponent
                    name="Street"
                    value={data.Street}
                    placeholder={t('LabelAddress') + "*"}
                    input={this.onChange} 
                    floatLabelType="Auto"
                    cssClass={`${validation.hasError && validation.errors.some(e => e.id === "Street") ? "e-error" : ""}`}
                />
                { validation.hasError && validation.errors.some(e => (e.id === "Street") && (e.message !== null || e.message !== undefined)) 
                    && <span className="error">{validation.errors.find(e => e.id === "Street").message}</span> }
            </div>
            <div className="m-bottom-10">
                <DropDownListComponent
                    ref={ref => this.postalCodeRef = ref}
                    name="PostalCodeId" 
                    value={data.PostalCodeId} 
                    dataSource={lookup.postalCodes} 
                    filtering={this.onPostalCodeFiltering.bind(this)} 
                    filterBarPlaceholder={t('SearchPostalCode')}
                    allowFiltering={true} 
                    placeholder={t('LabelPostalCode') + "*"}
                    fields={{ text: 'Value', value: 'Id' }} 
                    change={this.onPostalCodeChange} 
                    floatLabelType="Auto"
                    noRecordsTemplate={() => this.noRecordTemplate('PostalCodeNew')}
                    cssClass={`${validation.hasError && validation.errors.some(e => e.id === "PostalCodeId") ? "e-error" : ""}`}
                />
                { validation.hasError && validation.errors.some(e => (e.id === "PostalCodeId") && (e.message !== null || e.message !== undefined)) 
                    && <span className="error">{validation.errors.find(e => e.id === "PostalCodeId").message}</span> }
            </div>
            <div className="m-bottom-10">
                <DropDownListComponent
                    ref={ref => this.cityRef = ref}
                    name="CityId"
                    value={data.CityId} 
                    dataSource={lookup.cities} 
                    filtering={this.onCityFiltering.bind(this)} 
                    filterBarPlaceholder={t('SearchCity')}
                    allowFiltering={true} 
                    placeholder={t('LabelCity') + "*"}
                    fields={{ text: 'Name', value: 'Id' }} 
                    change={this.onCityChange} 
                    floatLabelType="Auto"
                    noRecordsTemplate={() => this.noRecordTemplate('CityNew')}
                    cssClass={`${validation.hasError && validation.errors.some(e => e.id === "CityId") ? "e-error" : ""}`}
                />
                { validation.hasError && validation.errors.some(e => (e.id === "CityId") && (e.message !== null || e.message !== undefined)) 
                    && <span className="error">{validation.errors.find(e => e.id === "CityId").message}</span> }
            </div>
            <div className="m-bottom-10">
                <DropDownListComponent
                    ref={ref => this.regionRef = ref}
                    name="RegionId" 
                    value={data.RegionId}
                    dataSource={lookup.regions}
                    filtering={this.onRegionFiltering.bind(this)} 
                    filterBarPlaceholder={t('SearchRegion')}
                    allowFiltering={true}
                    placeholder={t('LabelRegion') + "*"}
                    fields={{ text: 'Name', value: 'Id' }} 
                    change={this.onDropdownChange.bind(this, "RegionId")} 
                    floatLabelType="Auto"
                    noRecordsTemplate={() => this.noRecordTemplate('RegionNew')}
                    cssClass={`${validation.hasError && validation.errors.some(e => e.id === "RegionId") ? "e-error" : ""}`}
                />
                { validation.hasError && validation.errors.some(e => (e.id === "RegionId") && (e.message !== null || e.message !== undefined)) 
                    && <span className="error">{validation.errors.find(e => e.id === "RegionId").message}</span> }
            </div>
            <div className="m-bottom-10">
                <DropDownListComponent 
                    name="CountryId"
                    value={data.CountryId}
                    dataSource={lookup.countries}
                    filtering={this.onCountryFiltering.bind(this)} 
                    filterBarPlaceholder={t('SearchCountry')}
                    allowFiltering={true}
                    placeholder={t('LabelCountry') + "*"}
                    fields={{ text: 'Name', value: 'Id' }}
                    change={this.onDropdownChange.bind(this, "CountryId")} 
                    floatLabelType="Auto"
                    cssClass={`${validation.hasError && validation.errors.some(e => e.id === "CountryId") ? "e-error" : ""}`}
                />
                { validation.hasError && validation.errors.some(e => (e.id === "CountryId") && (e.message !== null || e.message !== undefined)) 
                    && <span className="error">{validation.errors.find(e => e.id === "CountryId").message}</span> }
            </div>
            <div className="m-bottom-10">
                <TextBoxComponent
                    name="PhoneNumber"
                    value={data.PhoneNumber}
                    placeholder={t('LabelTelephoneNumber') + "*"}
                    input={this.onChange} 
                    floatLabelType="Auto"
                    cssClass={`${validation.hasError && validation.errors.some(e => e.id === "PhoneNumber") ? "e-error" : ""}`}
                />
                { validation.hasError && validation.errors.some(e => (e.id === "PhoneNumber") && (e.message !== null || e.message !== undefined)) 
                    && <span className="error">{validation.errors.find(e => e.id === "PhoneNumber").message}</span> }
            </div>
            <div className="m-bottom-10">
                <TextBoxComponent
                    name="ContactMail"
                    value={data.ContactMail}
                    placeholder={t('LabelMail') + "*"}
                    input={this.onChange} 
                    floatLabelType="Auto" 
                    cssClass={`${validation.hasError && validation.errors.some(e => e.id === "ContactMail") ? "e-error" : ""}`}
                />
                { validation.hasError && validation.errors.some(e => (e.id === "ContactMail") && (e.message !== null || e.message !== undefined)) 
                    && <span className="error">{validation.errors.find(e => e.id === "ContactMail").message}</span> }
            </div>
            {
                i18n.language === "da" &&
                <div className="m-bottom-10">
                    <TextBoxComponent
                        name="VatNo"
                        value={data.VatNo}
                        placeholder={t('LabelCvrNo') + "*"}
                        input={this.onChange} 
                        floatLabelType="Auto" 
                        cssClass={`${validation.hasError && validation.errors.some(e => e.id === "VatNo") ? "e-error" : ""}`}
                    />
                    { validation.hasError && validation.errors.some(e => (e.id === "VatNo") && (e.message !== null || e.message !== undefined)) 
                        && <span className="error">{validation.errors.find(e => e.id === "VatNo").message}</span> }
                </div>
            }
            <div className="m-bottom-10">
                <TextBoxComponent
                    name="InternalAccountId"
                    value={data.InternalAccountId}
                    placeholder={t('LabelInternalAccountNo')}
                    input={this.onChange} 
                    floatLabelType="Auto" 
                />
            </div>
        </div>;
    }
}