import React, { Component } from 'react';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import Axios from 'axios'; 

import '../../styles/base.scss';
import '../../styles/oilmatregistration.scss';
import OilmatLogo from '../../assets/logo_oilmat_ilx_system.png';
import loadingGif from '../../assets/preloader.gif';

import OilmatAcceptance from './OilmatRegistration/OilmatAcceptance';
import OilmatWholesaler from './OilmatRegistration/OilmatWholesaler';
import OilmatCompanyData from './OilmatRegistration/OilmatCompanyData';
import OilmatSupplier from './OilmatRegistration/OilmatSupplier';
import OilmatUserData from './OilmatRegistration/OilmatUserData';
import OilmatDataConfirmation from './OilmatRegistration/OilmatDataConfirmation';

import oilmatRegistrationService from "../../services/OilmatRegistrationService";
import regionService from '../../services/RegionService';

import { withTranslation } from 'react-i18next';
import OilmatCountrySelection from './OilmatRegistration/OilmatCountrySelection';
import i18n from '../../utilities/Internationalization';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

class OilmatRegistration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentStep: -1,
            data: {
                acceptedTermsAndConditions: false,
                supplierSameAsInvoiceAddress: false,
                countryOfOrigin: null,
                countryOfOriginName: null,
                wholesalerNo: null,
                WholesalerId: null,
                CompanyName: null,
                Street: null,
                PostalCodeId: null,
                PostalCodeName: null,
                PostalCodeNew: null,
                CityId: null,
                CityName: null,
                CityNew: null,
                RegionId: null,
                RegionName: null,
                RegionNew: null,
                CountryId: null,
                CountryName: null,
                PhoneNumber: null,
                ContactMail: null,
                VatNo: null,
                InternalAccountId: null,
                DeliveryAddress: {
                    PhoneNumber: null,
                    DeliveryPostalCodeId: null,
                    DeliveryPostalCodeName: null,
                    DeliveryPostalCodeNew: null,
                    Email: null,
                    DeliveryStreet: null,
                    CityId: null,
                    CityName: null,
                    CityNew: null,
                    RegionId: null,
                    RegionName: null,
                    RegionNew: null,
                    CountryId: null,
                    CountryName: null,
                },
                User: {
                    Username: null,
                    FirstName: null,
                    MiddleName: null,
                    LastName: null,
                    Email: null,
                    Initials: null,
                    PhoneNumber: null
                }
            },
            lookup: {
                countries: [],
                regions: [],
                cities: [],
                postalCodes: [],
                suppliers: []
            },
            validation: {
                hasError: false,
                errors: []
            },
            loading: false
        }

        this.registrationSteps = [
            { number: 1, title: 'TitleWholesaler' },
            { number: 2, title: 'TitleCompanyData' },
            { number: 3, title: 'TitleSupplierAddress' },
            { number: 4, title: 'TitleUserData' },
            { number: 5, title: 'TitleOverview' },
        ]
    }

    componentDidMount() {
        Promise.all([
            regionService.GetRegion_Oilmat(source.token),
            regionService.GetCountry_Oilmat(source.token)
        ])
        .then(([regions, countries]) => {
            const { lookup } = this.state;

            if (regions && regions.Data && regions.Data !== null && regions.Data.length > 0) {
                regions.Data.forEach(region => {
                    lookup.regions.push(region);
    
                    region.Cities.forEach(city => {    
                        lookup.cities.push(city);
    
                        city.PostalCodes.forEach(postal => {
                            lookup.postalCodes.push(postal);
                        })
                    });
                });
            }

            if (countries && countries.Data && countries.Data !== null && countries.Data.length > 0) {
                countries.Data.forEach(country => {
                    lookup.countries.push(country);
                });
            }

            this.setState({ lookup });
        });
    }

    onClickNext = () => {
        const { currentStep } = this.state;

        if (currentStep !== 5) {
            let validationResult = null;

            switch (currentStep) {
                case 2:
                    validationResult = this.validateCompanyData();
                    break;
                case 3:
                    validationResult = this.validateSupplierData();
                    break;
                case 4:
                    validationResult = this.validateUserData();
                    break;
            }
    
            if (validationResult) {
                const { isValid, errors } = validationResult;
                if (!isValid) {
                    this.setState({ validation: { hasError: !isValid, errors } });
                    return;
                }
            }
    
            this.setState({
                currentStep: currentStep + 1,
                validation: { hasError: false, errors: [] }
            });
        }
        else {
            this.onSubmit();
        }
    }

    onClickPrevious = () => {
        this.setState({ currentStep: this.state.currentStep - 1 });
    }

    onSubmit = () => {
        this.toggleLoading(true);

        oilmatRegistrationService.Register(this.state.data, source.token).then(response => {
            this.toggleLoading(false);

            if (response.Success) {
                this.setState({ currentStep: 7 });
            }
            else {
                // CST - string
                // MCU - string
                // USRACC - string | array<ValidationErrorResultDTO>
                // USR - string
                // DPT - string
                // ACC - string

                let errors = [];
                try {
                    var errorList = JSON.parse(response.ErrorMessage);
                    errorList.forEach(error => {
                        errors.push({ message: error.Message });
                    })
                }
                catch {
                    // if ErrorMessage is not a json string then it is just a string
                    errors.push({ message: response.ErrorMessage });
                }

                this.setState({ validation: { hasError: true, errors } });
            }
        }).catch(error => {
            this.toggleLoading(false);

            let errors = [];
            errors.push({ message: this.props.t('GenericRegistrationErrorMessage') });
            this.setState({ validation: { hasError: true, errors } });
        });
    }

    validateCompanyData() {
        const { data } = this.state;
        let isValid = true;
        let errors = [];

        if (data.CompanyName === null || data.CompanyName === '') {
            isValid = false;
            errors.push({ id: "CompanyName" });
        }

        if (data.Street === null || data.Street === '') {
            isValid = false;
            errors.push({ id: "Street" });
        }

        if (data.PhoneNumber === null || data.PhoneNumber === '') {
            isValid = false;
            errors.push({ id: "PhoneNumber" });
        }

        if (data.ContactMail === null || data.ContactMail === '') {
            isValid = false;
            errors.push({ id: "ContactMail" });
        }
        else if (!emailRegex.test(data.ContactMail)) {
            isValid = false;
            errors.push({ id: "ContactMail" });
        }

        if (i18n.language ==='da') {
            if (!data.VatNo || data.VatNo === '') {
                isValid = false;
                errors.push({ id: "VatNo" });
            }
        }

        if (data.PostalCodeId === null || data.PostalCodeId === '') {
            isValid = false;
            errors.push({ id: "PostalCodeId" });
        }

        if (data.CityId === null || data.CityId === '') {
            isValid = false;
            errors.push({ id: "CityId" });
        }

        if (data.RegionId === null || data.RegionId === '') {
            isValid = false;
            errors.push({ id: "RegionId" });
        }

        if (data.CountryId === null || data.CountryId === '') {
            isValid = false;
            errors.push({ id: "CountryId" });
        }

        return {
            isValid,
            errors
        }
    }

    validateSupplierData() {
        const { data } = this.state;
        let isValid = true;
        let errors = [];
        
        if (!data.DeliveryAddress.DeliveryStreet) {
            isValid = false;
            errors.push({ id: "DeliveryAddress.DeliveryStreet" });
        }

        if (!data.DeliveryAddress.PhoneNumber) {
            isValid = false;
            errors.push({ id: "DeliveryAddress.PhoneNumber" });
        }
        
        if (!data.DeliveryAddress.Email) {
            isValid = false;
            errors.push({ id: "DeliveryAddress.Email" });
        }
        else if (!emailRegex.test(data.DeliveryAddress.Email)) {
            isValid = false;
            errors.push({ id: "DeliveryAddress.Email" });
        }

        if (!data.DeliveryAddress.DeliveryPostalCodeId) {
            isValid = false;
            errors.push({ id: "DeliveryAddress.DeliveryPostalCodeId" });
        }

        if (!data.DeliveryAddress.CityId) {
            isValid = false;
            errors.push({ id: "DeliveryAddress.CityId" });
        }

        if (!data.DeliveryAddress.RegionId) {
            isValid = false;
            errors.push({ id: "DeliveryAddress.RegionId" });
        }

        if (!data.DeliveryAddress.CountryId) {
            isValid = false;
            errors.push({ id: "DeliveryAddress.CountryId" });
        }

        return {
            isValid,
            errors
        }
    }

    validateUserData() {
        const { data } = this.state;
        let isValid = true;
        let errors = [];
        
        if (!data.User.FirstName) {
            isValid = false;
            errors.push({ id: "User.FirstName" });
        }
        
        if (!data.User.Username) {
            isValid = false;
            errors.push({ id: "User.Username" });
        }

        return {
            isValid,
            errors
        }
    }

    onChange = (key, value) => {
        this.setState(prevState => {
            const keys = key.split('.');
            const lastKey = keys.pop();
            const updateNestedState = (obj, keys) => {
                if (keys.length === 0) {
                    return { ...obj, [lastKey]: value };
                }
                
                const currentKey = keys[0];
                return {
                    ...obj,
                    [currentKey]: updateNestedState(obj[currentKey] || {}, keys.slice(1))
                };
            };

            return {
                data: {
                    ...prevState.data,
                    ...updateNestedState(prevState.data, keys)
                }
            };
        });
    }

    onSwitchStep = (step) => {
        this.setState({ currentStep: step, validation: { hasError: false, errors: [] } });
    }

    toggleLoading = (value) => {
        this.setState({ loading: value })
    }

    onAddMissingItem = (name, value, callback) => {
        const { lookup, data } = this.state;

        var newId = null;

        switch (name) {
            case 'PostalCodeNew':
                var maxId = lookup.postalCodes.reduce((max, obj) => {
                    return obj.Id > max ? obj.Id : max;
                }, 0);
                
                newId = maxId + 1;
                data.PostalCodeId = newId;
                data.PostalCodeNew = value;
                lookup.postalCodes.push({
                    Id: newId,
                    Value: value
                });
                break;
            case 'CityNew':
                var maxId = lookup.cities.reduce((max, obj) => {
                    return obj.Id > max ? obj.Id : max;
                }, 0);
                newId = maxId + 1;
                data.CityId = newId;
                data.CityNew = value;
                lookup.cities.push({
                    Id: newId,
                    Name: value
                });
                break;
            case 'RegionNew':
                var maxId = lookup.regions.reduce((max, obj) => {
                    return obj.Id > max ? obj.Id : max;
                }, 0);
                newId = maxId + 1;
                data.RegionId = newId;
                data.RegionNew = value;
                lookup.regions.push({
                    Id: newId,
                    Name: value
                });
                break;
        }

        this.setState({ lookup, data }, () => {
            if (callback) {
                callback(newId);
            }
        });
    }

    onSelectCountry = (code) => {
        if (code === 'DK') {
            i18n.changeLanguage('da');
        }
        else {
            i18n.changeLanguage('en');
        }

        // get wholesalers by country
        source = CancelToken.source();
        oilmatRegistrationService.GetWholeSalerByCountry(this.state.data.countryOfOrigin, source.token)
            .then(results => {
                const { lookup } = this.state;
                if (results && results.Data && results.Data !== null && results.Data.length > 0) {
                    results.Data.forEach(supplier => {
                        lookup.suppliers.push(supplier);
                    });

                    this.setState({ lookup });
                }
            });

        this.onClickNext();
    }

    render() {
        const { currentStep, data, lookup, validation, loading } = this.state;
        const { t } = this.props;
        
        let component = null;

        switch (currentStep) {
            case -1:
                return <OilmatCountrySelection data={data} onNext={this.onClickNext} lookup={lookup} onChange={this.onChange} onSelectCountry={this.onSelectCountry} t={t} />;
            case 0:
                return <OilmatAcceptance data={data} onNext={this.onClickNext} onPrevious={this.onClickPrevious} onChange={this.onChange} t={t} />;
            case 1:
                component = <OilmatWholesaler data={data} onChange={this.onChange} onSearch={this.toggleLoading} supplierList={lookup.suppliers} t={t} />;
                break;
            case 2:
                component = <OilmatCompanyData validation={validation} lookup={lookup} data={data} onChange={this.onChange} onAddMissingItem={this.onAddMissingItem} t={t} />;
                break;
            case 3:
                component = <OilmatSupplier validation={validation} lookup={lookup} data={data} onChange={this.onChange} onAddMissingItem={this.onAddMissingItem} t={t} />;
                break;
            case 4:
                component = <OilmatUserData validation={validation} data={data} onChange={this.onChange} t={t} />;
                break;
            case 5:
                component = <OilmatDataConfirmation data={data} onSwitchStep={this.onSwitchStep} onSearch={this.toggleLoading} validation={validation} t={t} />;
                break;
            default:
                break;
        }

        if (currentStep >= 1 && currentStep <= 5) {
            return (
                <>
                    {
                        loading && <div className="modal-backdrop">
                            <div className="loader"><img src={loadingGif} /></div>
                        </div>
                    }
                    <div className="oilmat-logo">
                        <img src={OilmatLogo} />
                    </div>
                    <div className="container flex-col registration-container">
                        <div className="container steps-container">
                            <div className="step-line"></div>
                            {
                                this.registrationSteps.map(step => {
                                    return (
                                        <div className={`container steps-inner-container ${step.number === currentStep ? 'active' : ''}`} key={step.number}>
                                            <div className="container step-number">{step.number}</div>
                                            <div className="step-title">{t(step.title)}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="button-container">
                            <div>
                                {
                                    currentStep > 1 &&
                                    <ButtonComponent 
                                        onClick={this.onClickPrevious}>
                                            {t('Back')}
                                    </ButtonComponent>
                                }
                                <ButtonComponent
                                    disabled={data.WholesalerId === null}
                                    onClick={this.onClickNext}
                                    isPrimary>
                                        {currentStep === 5 ? t('Submit') : t('Next')}
                                </ButtonComponent>
                            </div>
                        </div>
                        <div className="registration-content-container">
                            {component}
                        </div>
                    </div>
                </>
            )
        }

        return (
            <>
                <div className="oilmat-logo">
                    <img src={OilmatLogo} />
                </div>
                <div className="container flex-col" style={{ height: '50vh' }}>
                    <div className="success-title m-bottom-15">{t('SuccessAccountCreation')}</div>
                    <p>{t('CheckYourEmailForCredentials')}</p>
                    <p>{t('YouCanCloseWindow')}</p>
                </div>
            </>
        )
    }
}

export default withTranslation()(OilmatRegistration);