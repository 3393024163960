import React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Edit, Search, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import { RadioButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Query } from '@syncfusion/ej2-data';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { Redirect } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Axios from 'axios';

import BaseComponent from '../../components/BaseComponent';
import AppContext from '../../context/AppContext';

import regionService from '../../services/RegionService';
import CustomerGroupService from '../../services/Customer/CustomerGroupService';
import CustomerService from '../../services/Customer/CustomerService';
import SessionService from '../../services/SessionService';
import Loading from '../../components/Loading';
import Localization from '../../utilities/Localization';
import MessageHelper from '../../utilities/MessageHelper';

import { StorageKeys } from '../../settings/StorageKeys';
import SettingsService from '../../services/SettingsService';
import { buildGridSettings } from '../../utilities/Helper';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

class Customers extends BaseComponent {
    moduleName = "Kunde";
    pageName = "Kunder";
    gridSettingsKey = "CustomerList";
    hasDraggedColumn = false;
    gridSettings = '';
    defaultData = () => {
        return {
            Id : null,
            AccountId : 0,
            CustomerNo : '',
            CompanyName : '',
            Street : '',
            PostalCodeId : null,
            CityId : null,
            RegionId : null,
            CountryId : null,
            PhoneNumber : '',
            Email : '',
            VatNo : '',
            CustomerGroupId : null,
            DistributorId : null,
            WholesalerId : null,
            IntUserName : '',
            FirstName : '',
            MiddleName : '',
            LastName : '',
            CreationDate: null,
            OilmatCustomer: null,
            FlexmatCustomer: null,
            IsOilmatWholesaler: false,
            PostalCodeNew: null,
            CityNew: null,
            RegionNew: null            
        }
    };
    isILXAdmin = false;
    oilmatMcu = [];
    flexmatMachines = [];

    constructor() {
        super();

        this.state = {
            loading: true,
            data: {},
            redirect: {
                to: '',
                isRedirect: false
            },
            currentTabSelected: 0,
            selectedFlexmatMachine: null,
            isActive: true,
            isShowGrossist: false,
            isShowDist: false
        }

        this.queryText = '';
        this.pageSettings = {
            pageSize: 10,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.secondaryGridPageSettings = {
            pageSize: 5,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.commonValidationRules = { required: true };

        this.selectionSettings = {
            type: 'Single'
        };

        this.filterSettings = {
            type: 'Menu'
        };

        this.isSave = false;
        
        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.dialogInstance.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
        }
        }];

        // this.customerDetailsButtons = this.customerDetailsButtons();
        this.confirmDeleteButtons = [
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    this.confirmDeleteDialog.hide();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    isPrimary: true,
                    content: 'OK'
                }
            }
        ];
        /* End Dialog options  */

        this.init = this.init.bind(this);
        this.customerStatusTemplate = this.customerStatusTemplate.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
        this.validate = this.validate.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);
        this.executeCustomer = this.executeCustomer.bind(this);
        this.editCustomerTemplate = this.editCustomerTemplate.bind(this);
        this.loginAsCustomer = this.loginAsCustomer.bind(this);
        this.onFlexmatRowSelected = this.onFlexmatRowSelected.bind(this);

        this.regionSource = []
        this.postalCodes = [];
        this.cities = [];
        this.regions = [];
        this.countries = [];
        this.customerGroups = [];
        this.distributors = [];
        this.wholesalers = [];

        this.onChange = this.onChange.bind(this);
        this.onCheckChange = this.onCheckChange.bind(this);
        this.onPostalCodeChange = this.onPostalCodeChange.bind(this);
        this.onCityChange = this.onCityChange.bind(this);
        this.onRegionChange = this.onRegionChange.bind(this);
        this.onCountryChange = this.onCountryChange.bind(this);
        this.onDistributorDropdownChange = this.onDistributorDropdownChange.bind(this);
        this.onWholesalerDropdownChange = this.onWholesalerDropdownChange.bind(this);
        this.onCustomerGroupChange = this.onCustomerGroupChange.bind(this);
        this.onCvrChange = this.onCvrChange.bind(this);
        // this.onExtendSbuscriptionClick = this.onExtendSbuscriptionClick.bind(this);
        this.toggleActive = this.toggleActive.bind(this);
        this.updateAppAccess = this.updateAppAccess.bind(this);
        this.dropdownActiveChange = this.dropdownActiveChange.bind(this);

        this.onPostalCodeFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Value', 'startswith', e.text, true) : query;
            this.queryText = e.text;
            e.updateData(this.postalCodes, query)
        }

        this.onCityFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('ShortName', 'startswith', e.text, true) : query;
            this.queryText = e.text;
            e.updateData(this.cities, query)
        }

        this.onRegionFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            this.queryText = e.text;
            e.updateData(this.regions, query)
        }

        this.onCountryFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            e.updateData(this.countries, query)
        }
    }

    customerDetailsButtons = () => {
        return [
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    let data = this.defaultData();
                    this.setState({ data }, () => {
                        this.clearFieldError();
                        this.customerDetailDialog.hide();
                    });
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    content: 'Annuller'
                }
            },
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    const { data } = this.state;
                    const { hasError, errorList} = this.validate(data);
                    if (hasError) {
                        if (errorList.length <= 0) {
                            errorList.push("Du mangler at udfylde felter, makeret med rødt.");
                        }
                        
                        this.dialogOpen("Advarsel", errorList.join('<br>'));
                        return;
                    }

                    let state = data.Id ? 'update' : 'add';
                    this.executeCustomer(state, data);
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    isPrimary: true,
                    content: 'Gem'
                }
            }
        ];
    }

    oilmatButtons = () => {
        return [
            {
                // Click the footer buttons to hide the Dialog
                click: async () => {
                    let { data } = this.state;

                    if (data.OilmatCustomer.AllowExtend) {
                        const result = await CustomerService.UpdateOilmatSubscription(data.Id, source.token);
                    }


                    var payload = {
                        Id: data.Id,
                        AccountId: data.AccountId,
                        AppAccess: data.AppAccess
                    }

                    const result = await CustomerService.UpdateAppAccess(data.Id, payload, source.token);
                    if (!result.HasError) {
                        data = this.defaultData();
                        this.setState({ data, currentTabSelected: 0 }, async () => {
                            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "OilMat"));
                            const { isActive } = this.state;
                            await this.refreshGrid(isActive);
                            this.customerDetailDialog.hide();
                        });
                    }
                    else {
                        this.dialogOpen("Advarsel", result.ErrorMessage);
                    }
                },
                buttonModel: {
                    disabled: false,
                    isPrimary: true,
                    content: 'Gem'
                }
            }
        ];
    }

    flexmatButtons = () => {
        const { selectedFlexmatMachine } = this.state;
        let disableAllowExtendBtn = true; 
        if (selectedFlexmatMachine)
            disableAllowExtendBtn = !selectedFlexmatMachine.AllowExtend;

        return [
            {
                // Click the footer buttons to hide the Dialog
                click: async () => {
                    let { data } = this.state;
                    const result = await CustomerService.UpdateFlexmatSubscription(data.Id, selectedFlexmatMachine.ModuleId, source.token);
                    if (!result.HasError) {
                        data = this.defaultData();
                        this.setState({ data, currentTabSelected: 0, selectedFlexmatMachine: null }, async () => {
                            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "FlexMat"));
                            const { isActive } = this.state;
                            await this.refreshGrid(isActive);
                            this.customerDetailDialog.hide();
                        });
                    }
                    else {
                        this.dialogOpen("Advarsel", result.ErrorMessage);
                    }
                },
                buttonModel: {
                    disabled: disableAllowExtendBtn,
                    isPrimary: true,
                    content: 'Forny 12 måneder'
                }
            }
        ]
    }

    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        
        this.init();

        const sessionInfo = await SessionService.GetSessionInfo();
        this.CustomerId = sessionInfo.Id;
        const customers = await CustomerService.GetChildCustomers(sessionInfo.Id, { active: true }, source.token);
        this.data = customers;

        const customer = await CustomerService.GetCustomer(sessionInfo.Id, source.token);
        this.parentCustomer = customer;

        // if (customer.CustomerGroup.Id === 1) {
        //     this.isILXAdmin = true;
        // }

        const gridSettings = await SettingsService.GetGridSettings(this.gridSettingsKey, source.token);
        if (!gridSettings.HasError) {
            this.gridSettings = gridSettings.Settings;
        }

        Promise.all([
            regionService.GetRegion(source.token),
            SettingsService.GetCountries(source.token)
        ]).then(([regions, countries]) => {
            if (regions && regions.length > 0) {
                this.regionSource = regions;

                let tempRegions = [], tempCities = [];

                regions.forEach(region => {
                    tempRegions.push(region);

                    region.Cities.forEach(city => {
                        tempCities.push(city);

                        city.PostalCodes.forEach(postal => {
                            this.postalCodes.push(postal);
                        })
                    });
                });

                tempRegions = [...new Set(tempRegions)];
                tempCities = [...new Set(tempCities)];

                tempRegions.sort((a,b) => (a.value > b.value) ? 1 : -1);
                tempCities.sort((a,b) => (a.value > b.value) ? 1 : -1);

                this.cities = tempCities;
                this.regions = tempRegions;
            }

            if (countries && countries.length > 0) {
                this.countries = countries;
            }
        });

        let customerGroups = await CustomerGroupService.GetCustomerGroups(source.token);
        const parentCustomerGroup = Object.assign({}, customerGroups.find(x => x.Id === customer.CustomerGroup.Id));
        customerGroups = customerGroups.filter(x => x.Level > parentCustomerGroup.Level);
        customerGroups.sort((a,b) => (a.Level > b.Level) ? 1 : -1);

        this.customerGroups = customerGroups.map(x => {
            return {
                text: x.Name,
                Id: x.Id
            }
        });

        let tempDistributors = [], tempWholesalers = [];
        tempDistributors = customers.filter(x => x.CustomerGroupId === 2);
        tempWholesalers = customers.filter(x => x.CustomerGroupId === 3);

        this.distributors = tempDistributors.length <= 0 ? [] : tempDistributors.map(d => {
            return {
                Id: d.Id,
                Name: d.CompanyName
            }
        });
        this.wholesalers = tempWholesalers.length <= 0 ? [] : tempWholesalers.map(d => {
            return {
                Id: d.Id,
                Name: d.CompanyName
            }
        });

        this.setState({ loading: false }, () => {
            setTimeout(() => {
                if (this.customerGridRef) {
                    this.customerGridRef.toolbarModule.enableItems(3, false);
                    this.customerGridRef.toolbarModule.enableItems(4, false);
                }
            }, 300);

            window.addEventListener('wheel', this.onWheelScroll);
        });
    }

    init() {
        this.toolbarOptions = [];
        this.defaultEditSettings = {};

        if (super.hasMenuItemFunction("Kundeliste", "Ny")) {
            this.toolbarOptions.push({text: 'Ny', tooltipText: 'Ny', id: 'add', prefixIcon: 'e-add' });
        }
        if (super.hasMenuItemFunction("Kundeliste", "Redigere")) {
            this.toolbarOptions.push({text: 'Redigere', tooltipText: 'Redigere', id: 'edit', prefixIcon: 'e-edit'});
        }
        if (super.hasMenuItemFunction("Kundeliste", "Slette")) {
            this.toolbarOptions.push({text: 'Slette', tooltipText: 'Slette', id: 'delete', prefixIcon: 'e-delete'});
            this.defaultEditSettings = {
                allowDeleting: true,
                showDeleteConfirmDialog: true,
            };
        }

        this.toolbarOptions.push({ text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' });

        if (super.hasMenuItemFunction("Kundeliste", "Åben")) {
            this.isILXAdmin = true;
        }

        this.toolbarOptions.push({ template: this.customerStatusTemplate, align: "Right" });
    }

    async refreshGrid(isActive) {
        let data = {
            Id : null,
            AccountId : 0,
            CustomerNo : '',
            CompanyName : '',
            Street : '',
            PostalCodeId : null,
            CityId : null,
            RegionId : null,
            CountryId : null,
            PhoneNumber : '',
            Email : '',
            VatNo : '',
            CustomerGroupId : null,
            DistributorId : null,
            WholesalerId : null,
            IntUserName : '',
            FirstName : '',
            MiddleName : '',
            LastName : ''
        };

        this.setState({ data }, async () => {
            this.clearFieldError();
            const customers = await CustomerService.GetChildCustomers(this.CustomerId, { active: isActive }, source.token);
            this.customerGridRef.dataSource = customers;
            this.data = customers;
            this.customerGridRef.dataBind();
            
            let tempDistributors = [], tempWholesalers = [];
            tempDistributors = customers.filter(x => x.CustomerGroupId === 2);
            tempWholesalers = customers.filter(x => x.CustomerGroupId === 3);

            this.distributors = tempDistributors.length <= 0 ? [] : tempDistributors.map(d => {
                return {
                    Id: d.Id,
                    Name: d.CompanyName
                }
            });
            this.wholesalers = tempWholesalers.length <= 0 ? [] : tempWholesalers.map(d => {
                return {
                    Id: d.Id,
                    Name: d.CompanyName
                }
            });
        });
    }

    componentWillUnmount() {
        source.cancel();
        window.removeEventListener('wheel', this.onWheelScroll);
    }

    clearFieldError() {
        document.querySelector('input[name="CompanyName"]').parentElement.classList.remove('e-error');
        document.querySelector('#ddlPostalCodeId').parentElement.classList.remove('e-error');
        document.querySelector('input[name="IntUserName"]').parentElement.classList.remove('e-error');
        document.querySelector('input[name="FirstName"]').parentElement.classList.remove('e-error');
        document.querySelector('input[name="LastName"]').parentElement.classList.remove('e-error');
        document.querySelector('input[name="VatNo"]').parentElement.classList.remove('e-error');
        document.querySelector('input[name="Email"]').parentElement.classList.remove('e-error');
        document.querySelector('select[name="CustomerGroupId"]').parentElement.classList.remove('e-error');
        const { isShowGrossist } = this.state;
        if (isShowGrossist) {
            document.querySelector('select[name="WholesalerId"]').parentElement.classList.remove('e-error');
        }
        document.querySelector('input[name="PhoneNumber"]').parentElement.classList.remove('e-error');
    }

    validate(data) {
        let hasError = false, errorList = [];

        if (!data.CompanyName) { document.querySelector('input[name="CompanyName"]').parentElement.classList.add('e-error'); hasError = true;}
        else { document.querySelector('input[name="CompanyName"]').parentElement.classList.remove('e-error'); }

        if (!data.PostalCodeId) { document.querySelector('#ddlPostalCodeId').parentElement.classList.add('e-error'); hasError = true; }
        else { document.querySelector('#ddlPostalCodeId').parentElement.classList.remove('e-error'); }

        if (!data.IntUserName) { document.querySelector('input[name="IntUserName"]').parentElement.classList.add('e-error'); hasError = true; }
        else { document.querySelector('input[name="IntUserName"]').parentElement.classList.remove('e-error'); }

        if (!data.FirstName) { document.querySelector('input[name="FirstName"]').parentElement.classList.add('e-error'); hasError = true; }
        else { document.querySelector('input[name="FirstName"]').parentElement.classList.remove('e-error'); }

        if (!data.LastName) { document.querySelector('input[name="LastName"]').parentElement.classList.add('e-error'); hasError = true; }
        else { document.querySelector('input[name="LastName"]').parentElement.classList.remove('e-error'); }

        if (data.VatNo === null || data.VatNo === '') { document.querySelector('input[name="VatNo"]').parentElement.classList.add('e-error'); hasError = true; }
        else { document.querySelector('input[name="VatNo"]').parentElement.classList.remove('e-error'); }

        if (!data.Email) { document.querySelector('input[name="Email"]').parentElement.classList.add('e-error'); hasError = true; }
        else { document.querySelector('input[name="Email"]').parentElement.classList.remove('e-error'); }

        if (!data.CustomerGroupId) { document.querySelector('select[name="CustomerGroupId"]').parentElement.classList.add('e-error'); hasError = true; }
        else { document.querySelector('select[name="CustomerGroupId"]').parentElement.classList.remove('e-error'); }

        // matches alphanumeric, case insensitive
        if (data.IntUserName && !data.IntUserName.match(/^[a-z0-9@_.]+$/i)) {
            hasError = true;
            errorList.push(Localization.Customer.ErrorInvalidUserName);
            document.querySelector('input[name="IntUserName"]').parentElement.classList.add('e-error');
        }
        else {
            document.querySelector('input[name="IntUserName"]').parentElement.classList.remove('e-error');
        }

        if (data.PhoneNumber && !data.PhoneNumber.match(/^[ +0-9]+$/)) {
            hasError = true;
            errorList.push(Localization.Customer.ErrorInvalidTelephone);
            document.querySelector('input[name="PhoneNumber"]').parentElement.classList.add('e-error');
        }
        else {
            document.querySelector('input[name="PhoneNumber"]').parentElement.classList.remove('e-error');
        }

        const { isShowGrossist } = this.state;

        if(data.CustomerGroupId === 4 && !data.WholesalerId) {
            hasError = true;
            errorList.push(Localization.Customer.ErrorPleaseSelectWholeSaler);
            document.querySelector('select[name="WholesalerId"]').parentElement.classList.add('e-error');
        } else if (isShowGrossist) {
            document.querySelector('select[name="WholesalerId"]').parentElement.classList.remove('e-error');
        }

        return { hasError, errorList};
    }

    actionBegin(args) {
        // if (args.requestType === 'beginEdit' || (args.requestType === 'add' && args.type === 'actionBegin') || (args.requestType === 'save' && args.type === 'actionBegin')) {
        //     this.isSave = false;
        // }

        // if (args.requestType === "save" && !this.isSave) {
        //     args.cancel = true;
        //     this.isSave = false;

        //     const { hasError, errorList} = this.validate(args);
        //     if (hasError) {
        //         if (errorList.length <= 0) {
        //             errorList.push("Du mangler at udfylde felter, makeret med rødt.");
        //         }
                
        //         this.dialogOpen("Advarsel", errorList.join('<br>'));
        //         return;
        //     }

        //     if (args.action === 'add') {
        //         this.executeCustomer('add', args.data);
        //     }
        //     else if (args.action === 'edit') {
        //         this.executeCustomer('update', args.data);
        //     }
        // }
        // else 
        // if (args.requestType === 'delete') {
        //     args.cancel = true;
        //     this.executeCustomer('delete', args.data);
        // }
    }

    async executeCustomer(state, data, params = null) {
        let result = null;
        let messageActionText = "";
        
        switch (state) {
            case 'add':
                data.ParentId = this.CustomerId;
                data.VatNo = data.VatNo.toString();
        
                data.DistributorId = data.CustomerGroupId === 2 ? null : data.DistributorId;
                data.WholesalerId = data.CustomerGroupId === 3 ? null : data.WholesalerId;

                data.Active = true;
                data.FmUserName = data.IntUserName;
                result = await CustomerService.AddUpdateCustomer('add', data, source.token);
                messageActionText = Localization.General.SuccessAdd.replace("{context}", "Kunde");
                break;
            case 'update':
                data.ParentId = this.CustomerId;
                data.VatNo = data.VatNo.toString();

                data.DistributorId = data.CustomerGroupId === 2 ? null : data.DistributorId;
                data.WholesalerId = data.CustomerGroupId === 3 ? null : data.WholesalerId;

                data.FmUserName = data.IntUserName;
                result = await CustomerService.AddUpdateCustomer('update', data, source.token);
                messageActionText = Localization.General.SuccessUpdate.replace("{context}", "Kunde");
                break;
            case 'delete':
                result = await CustomerService.DeleteCustomer(data, params, source.token);
                messageActionText = Localization.General.SuccessDelete.replace("{context}", "Kunde");
                break;
            default:
                break;
        }
        
        if (!result.HasError) {
            // this.isSave = true;

            this.setState({ data: this.defaultData() }, async () => {
                this.confirmDeleteDialog.hide();
                const { isActive } = this.state;
                await this.refreshGrid(isActive);
                this.dialogOpen(Localization.General.SuccessHeader, messageActionText);
    
                if (state === 'add' || state === 'update') {
                    this.customerDetailDialog.hide();
                }
            });
        }
        else {
            // this.isSave = true;
            this.dialogOpen("Advarsel", result.ErrorMessage);
        }
    }

    customerStatusTemplate = () => {
        const items = [
            { item: 'Aktiv Kunder', value: true },
            { item: 'Inaktiv Kunder', value: false }
        ];

        return (<DropDownListComponent id="ddlCustomerStatus" cssClass="customer-status-dropdown" dataSource={items} placeholder="Vælg" value={true} fields= {{ text: 'item', value: 'value' }} change={this.dropdownActiveChange} />)
    }

    dropdownActiveChange(args) {
        let { isActive } = this.state;
        isActive = args.itemData.value;
        this.refreshGrid(isActive);
        this.setState({ isActive });
    }

    async clickHandler(args) {
        if (args.item.id === 'add') {
            let data = this.defaultData();
            this.setState({ data, isShowGrossist: false, isShowDist: false }, () => {
                this.customerDetailDialog.header = "Tilføj Kunde";
                this.customerDetailDialog.show();
            });
        }
        else if (args.item.id === 'edit') {
            const selectedRow = this.customerGridRef.getSelectedRecords();
            let { data, isShowGrossist, isShowDist } = this.state;
            
            if (selectedRow.length <= 0) {
                this.dialogOpen("Advarsel", Localization.General.EditNoRowSelection.replace("{context}", "kunde"));
                return;
            }

            data.Id = selectedRow[0].Id;
            data.AccountId = selectedRow[0].AccountId;
            data.CustomerNo = selectedRow[0].CustomerNo;
            data.CompanyName = selectedRow[0].CompanyName;
            data.Street = selectedRow[0].Street;
            data.PostalCodeId = selectedRow[0].PostalCodeId;
            data.CityId = selectedRow[0].CityId;
            data.RegionId = selectedRow[0].RegionId;
            data.CountryId = selectedRow[0].CountryId;
            data.Country = selectedRow[0].Country;
            data.PhoneNumber = selectedRow[0].PhoneNumber;
            data.Email = selectedRow[0].Email;
            data.VatNo = parseInt(selectedRow[0].VatNo);
            data.CustomerGroupId = selectedRow[0].CustomerGroupId;
            data.DistributorId = selectedRow[0].DistributorId;
            data.WholesalerId = selectedRow[0].WholesalerId;
            data.IntUserName = selectedRow[0].IntUserName;
            data.FirstName = selectedRow[0].FirstName;
            data.MiddleName = selectedRow[0].MiddleName;
            data.LastName = selectedRow[0].LastName;
            data.ContactMail = selectedRow[0].ContactMail;
            data.CreationDate = selectedRow[0].CreationDate;
            data.OilmatCustomer = selectedRow[0].OilmatCustomer;
            data.FlexmatCustomer = selectedRow[0].FlexmatCustomer;
            data.Active = selectedRow[0].Account.Active;
            data.AppAccess = selectedRow[0].Account.AppAccess;
            data.IsOilmatWholesaler = selectedRow[0].IsOilmatWholesaler;

            if (data.CustomerGroupId === 2) {
                isShowGrossist = false;
                isShowDist = false;
            } else if (data.CustomerGroupId === 3) {
                isShowDist = true;
                isShowGrossist = false;
            } else if (data.CustomerGroupId === 4) {
                isShowDist = false;
                isShowGrossist = true;
            }

            this.customerDetailDialog.buttons = this.customerDetailsButtons();
            this.setState({ data, isShowGrossist, isShowDist }, () => {
                this.customerDetailDialog.header = "Rediger Kunde";
                this.customerDetailDialog.show();
            });
        }
        else if (this.customerGridRef && args.item.id === 'undo') {
            const selectedRow = this.customerGridRef.getSelectedRecords();

            if (selectedRow.length > 0) {
                source.cancel();
                source = CancelToken.source();
                const result = await CustomerService.UndoCustomer(selectedRow[0].Id, source.token);

                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Kunde"));
                    const { isActive } = this.state;
                    this.refreshGrid(isActive);
                }
                else {
                    this.dialogOpen("Advarsel", result.ErrorMessage);
                }
            }
            else {
                this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
            }
        }
        else if (this.customerGridRef && args.item.id === 'redo') {
            const selectedRow = this.customerGridRef.getSelectedRecords();

            if (selectedRow.length > 0) {
                source.cancel();
                source = CancelToken.source();
                const result = await CustomerService.RedoCustomer(selectedRow[0].Id, source.token);

                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Kunde"));
                    const { isActive } = this.state;
                    this.refreshGrid(isActive);
                }
                else {
                    this.dialogOpen("Advarsel", result.ErrorMessage);
                }
            }
            else {
                this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
            }
        }
        else if (args.item.id === 'delete') {
            const selectedRows = this.customerGridRef.getSelectedRecords();
            if (selectedRows.length <= 0) {
                this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
            }
            else {
                let buttons = null;
                let selectedCustomer = selectedRows[0];

                if (selectedCustomer.InUse && selectedCustomer.Account.Active) {
                    buttons = MessageHelper.MB3.buttons;

                    buttons[0].click = () => {
                        this.confirmDeleteDialog.hide();
                    };

                    buttons[1].click = async () => {
                        await this.executeCustomer('delete', selectedCustomer.Id, { force: true });
                    }

                    this.confirmDeleteDialog.content = MessageHelper.MB3.message;
                }
                else {
                    buttons = this.confirmDeleteButtons;

                    buttons[1].click = async () => {
                        await this.executeCustomer('delete', selectedCustomer.Id, { force: false });
                    }

                    this.confirmDeleteDialog.content = 'Er du sikker på at du vil slette data?';
                }

                this.confirmDeleteDialog.buttons = buttons;
                this.confirmDeleteDialog.show();
            }
        }
        else if (args.item.id === 'reset_filter') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                settings.filters = [];
                this.gridSettings = settings;
                const payload = {
                    Key: this.gridSettingsKey,
                    Settings: JSON.stringify(this.gridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.customerGridRef.clearFiltering();
        }
    }

    onTabSelected = (index) => {
        let { data, selectedFlexmatMachine } = this.state;
        switch(index) {
            case 0:
                this.customerDetailDialog.buttons = this.customerDetailsButtons();
                selectedFlexmatMachine = null;
                break;
            case 1:
                this.oilmatMcu = data.OilmatCustomer.Mcus;
                this.customerDetailDialog.buttons = this.oilmatButtons();
                selectedFlexmatMachine = null;
                break;
            case 2:
                this.flexmatMachines = data.FlexmatCustomer.CustomerMachines;
                this.customerDetailDialog.buttons = this.flexmatButtons();
                break;
        }
        this.setState({ currentTabSelected: index, selectedFlexmatMachine });
    }

    // async onExtendSbuscriptionClick(args) {
    //     let { data } = this.state;
    //     const result = await CustomerService.UpdateSubscription(data.Id, source.token);
    //     if (!result.HasError) {
    //         data.OilmatCustomer.SubscriptionEndDate = result.OilmatCustomer.SubscriptionEndDate;
    //         this.setState({ data });
    //         this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "OilMat"));
    //     }
    //     else {
    //         this.dialogOpen("Advarsel", result.ErrorMessage);
    //     }
    // }

    onFlexmatRowSelected(args) {
        if(args.isInteracted) {
            let { selectedFlexmatMachine } = this.state;
            selectedFlexmatMachine = args.data;
            this.setState({ selectedFlexmatMachine }, () => {
                this.customerDetailDialog.buttons = this.flexmatButtons();
            });
        }
    }

    flexmatMachineName(props) {
        return <span>{props.CustomName + "/" + props.ModuleName}</span>;
    }

    dialogOpen(header, content) {
        this.dialogInstance.header = header;
        this.dialogInstance.content = content;
        this.dialogInstance.show();
    }

    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onCreated() {
        if (this.gridSettings) {
            let settings = JSON.parse(this.gridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.customerGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.customerGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.customerGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.customerGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.customerGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.customerGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.customerGridRef.columns.length - 1;    
                this.customerGridRef.columns[lastColumnIndex].allowResizing = false;
                this.customerGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.customerGridRef.pageSettings.pageSize = this.customerGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#customer-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.customerGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    async onActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.customerGridRef.pageSettings.pageSize === this.customerGridRef.pageSettings.totalRecordsCount || this.customerGridRef.pageSettings.pageSize === this.data.length) {
                document.querySelector("#customer-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, this.customerGridRef.pageSettings.pageSize, this.customerGridRef.pageSettings.totalRecordsCount, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };
            
            await SettingsService.SaveGridSettings(payload, source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.customerGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.customerGridRef.columns.length - 1;
                for (let index = 0; index < this.customerGridRef.columns.length; index++) {
                    let column = this.customerGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.customerGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
    }

    async onResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.customerGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, columns);
        this.gridSettings = tempGridSettings;
        const payload = {
            Key: this.gridSettingsKey,
            Settings: this.gridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.customerGridRef.columns.length - 1;
            let totalColumnWidth = this.customerGridRef.columns.reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.customerGridRef.element.clientWidth - totalColumnWidth;
            this.customerGridRef.columns[lastColumnIndex].width = this.customerGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.customerGridRef.refreshColumns();
        }
    }

    onChange(args, name) {
        let input = args.container.querySelector('input');
    
        if (input) {
            let { data } = this.state;
            data[input.name] = args.value;
            this.setState({ data });
        }
    }

    onCheckChange(args) {
        let { data } = this.state;
        data[args.event.target.name] = args.checked;
        this.setState({ data });
    }

    onCvrChange(args) {
        let { data } = this.state;
        data.VatNo = args.value
        this.setState({ data });
    }

    onPostalCodeChange(args) {
        if (args.isInteracted) {
            let { data } = this.state;
            data.PostalCodeId = args.value;
            
            if (args.itemData.CityId) {
                let city = this.cities.find(c => c.Id === args.itemData.CityId);
    
                this.ddlCityRef.value = args.itemData.CityId;
    
                let selectedRegion = this.regionSource.find(x => x.Id === city.RegionId);
    
                this.ddlRegionRef.value = city.RegionId;
                this.ddlRegionRef.text =  selectedRegion.Name;
                
                data.CityId = args.itemData.CityId;
                data.RegionId = city.RegionId;
                data.CountryId = selectedRegion.CountryId;
            }

            this.setState({ data });
        }
    }

    onCityChange(args) {
        if (args.isInteracted) {
            let { data } = this.state;
            data.CityId = args.value;

            if (args.itemData.RegionId) {
                this.ddlRegionRef.text = null;

                let selectedRegion = this.regions.find(x => x.Id === args.itemData.RegionId);
                this.ddlRegionRef.value = args.itemData.RegionId;
                this.ddlRegionRef.text = selectedRegion.Name;
    
                data.RegionId = args.itemData.RegionId;
                data.CountryId = selectedRegion.CountryId;
            }

            this.setState({ data });
        }
    }

    onRegionChange(args) {
        if (args.isInteracted) {
            let { data } = this.state;
            data.RegionId = args.value;

            if (args.itemData.CountryId) {
                data.CountryId = args.itemData.CountryId;
            }
            
            this.setState({ data });
        }
    }

    onCountryChange(args) {
        if (args.isInteracted) {
            let { data } = this.state;
            data.CountryId = args.value;
            this.setState({ data });
        }
    }

    onCustomerGroupChange(args) {
        if (args.isInteracted) {
            let { data, isShowGrossist, isShowDist } = this.state;
            data.CustomerGroupId = args.value;
            
            // 1 - ILX, 2 - Distributor, 3 - Wholesaler, 4 - Enduser
            if (args.value === 2) {
                data.DistributorId = null;
                data.WholesalerId = null;
                isShowGrossist = false;
                isShowDist = false;
            }
    
            if (args.value === 3) {
                data.WholesalerId = null;
                isShowDist = true;
                isShowGrossist = false;
            }
    
            if (args.value === 4) {
                data.DistributorId = null;
                isShowDist = false;
                isShowGrossist = true;
            }
            
            this.setState({ data, isShowGrossist, isShowDist }, () => {    
                if (args.value === 2) {
                    // this.distributorRef.value = '';
                    // this.distributorRef.text = '';
                    // this.distributorRef.enabled = false;
                    
                    // this.wholesalerRef.value = '';
                    // this.wholesalerRef.text = '';
                    // this.wholesalerRef.enabled = false;
                }
        
                if (args.value === 3) {
                    // this.wholesalerRef.value = '';
                    // this.wholesalerRef.text = '';
                    // this.wholesalerRef.enabled = false;
                    
                    this.distributorRef.enabled = true;
                }
        
                if (args.value === 4) {
                    // this.distributorRef.value = '';
                    // this.distributorRef.text = '';
                    // this.distributorRef.enabled = false;
                    this.wholesalerRef.enabled = true;
                }
            });
        }
    }

    onDistributorDropdownChange(args) {
        if (args.isInteracted) {
            let { data } = this.state;
            data.DistributorId = args.value;
            this.setState({ data });
        }
    }

    onWholesalerDropdownChange(args) {
        if (args.isInteracted) {
            let { data } = this.state;
            data.WholesalerId = args.value;
            this.setState({ data });
        }
    }

    async loginAsCustomer(data) {
        const result = await SessionService.LoginAs(data.Username, source.token);
        if (!result.HasError) {
            localStorage.setItem(StorageKeys.temp_LoggedInAs_Token_Storage, result);
            window.open('/min-konto');
        }
    }

    editCustomerTemplate(props) {
        return (
            <i className="c-icon c-icon-external-link clickable" onClick={() => this.loginAsCustomer(props)}></i>
        )
    }

    oilmatSubscriptionTemplate(props) {
        if (!props.OilmatCustomer)
            return '';
            
        let text = props.Account.AppAccess ? "Gyldig" : "Udløbet";
        return <span>{text}</span>;
    }

    onWheelScroll = () => {
        let grid = document.getElementById('customer-grid');
        let toolbar = document.querySelector('#customer-grid.e-grid .e-toolbar');
        let tableHeader = document.querySelector('#customer-grid .e-gridheader');

        if (toolbar && tableHeader) {
            let boundingRect = tableHeader.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar.classList.add('c-hover-grid-toolbar');
                toolbar.style.width = (grid.clientWidth - 5) + 'px';
            }
            else {
                toolbar.classList.remove('c-hover-grid-toolbar');
                toolbar.style.width = 'auto';
            }
        }
    }

    toggleActive = () => {
        let { data } = this.state;
        data.Active = !data.Active;
        this.setState({ data });
    }

    updateAppAccess = async (args) => {
        let { data } = this.state;
        data[args.event.target.name] = args.value == 1;

        this.setState({ data });
    }

    addMissingItem = (name) => {
        var newId = null;
        const { data } = this.state;

        switch (name) {
            case 'PostalCodeNew':
                var maxId = this.postalCodes.reduce((max, obj) => {
                    return obj.Id > max ? obj.Id : max;
                }, 0);

                newId = maxId + 1;
                data.PostalCodeId = newId;
                data.PostalCodeNew = this.queryText;
                this.postalCodes.push({ Id: newId, Value: this.queryText });

                this.ddlPostalCodeRef.hidePopup();
                this.ddlPostalCodeRef.value = newId;
                this.ddlPostalCodeRef.refresh();
                break;
            case 'CityNew':
                var maxId = this.cities.reduce((max, obj) => {
                    return obj.Id > max ? obj.Id : max;
                }, 0);

                newId = maxId + 1;
                data.CityId = newId;
                data.CityNew = this.queryText;
                this.cities.push({ Id: newId, Name: this.queryText, ShortName: this.queryText });

                this.ddlCityRef.hidePopup();
                this.ddlCityRef.value = newId;
                this.ddlCityRef.refresh();
                break;
            case 'RegionNew':
                var maxId = this.regions.reduce((max, obj) => {
                    return obj.Id > max ? obj.Id : max;
                }, 0);

                newId = maxId + 1;
                data.RegionId = newId;
                data.RegionNew = this.queryText;
                this.regions.push({ Id: newId, Name: this.queryText });

                this.ddlRegionRef.hidePopup();
                this.ddlRegionRef.value = newId;
                this.ddlRegionRef.refresh();
                break;
        }
    }

    noRecordTemplate = (name) => {
        return <span>
            {Localization.General.NoRecordFound} <span className="clickable c-link" onClick={() => this.addMissingItem(name)}>{`Add ${this.queryText}?`}</span>
        </span>;
    }

    render() {
        const { loading, data, redirect, currentTabSelected, selectedFlexmatMachine, isShowGrossist, isShowDist } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        let mcusCount = 0, oilmatSubscriptionEndDate = null, allowExtendOilmat = false, createDate = null;
        if (data.CreationDate) {
            let tempCreateDate = new Date(data.CreationDate);
            createDate = `${tempCreateDate.getFullYear()}-${(tempCreateDate.getMonth() + 1).toString().padStart(2,0)}-${tempCreateDate.getDate()}`;
        }

        if (data.OilmatCustomer) {
            mcusCount = data.OilmatCustomer.Mcus.length;
            let tempSubDate = new Date(data.OilmatCustomer.SubscriptionEndDate);
            oilmatSubscriptionEndDate = `${tempSubDate.getFullYear()}-${(tempSubDate.getMonth() + 1).toString().padStart(2,0)}-${tempSubDate.getDate()}`;
            allowExtendOilmat = data.OilmatCustomer.AllowExtend;
        }

        let machinesCount = 0, flexmatSubscriptionEndDate = null, allowExtendFlexmat = false, moduleDateCreated = null;
        if (data.FlexmatCustomer) {
            machinesCount = data.FlexmatCustomer.CustomerMachines.length;
            if (selectedFlexmatMachine) {
                let tempSubDate = new Date(selectedFlexmatMachine.SubscriptionEndDate);
                flexmatSubscriptionEndDate = `${tempSubDate.getFullYear()}-${(tempSubDate.getMonth() + 1).toString().padStart(2,0)}-${tempSubDate.getDate()}`;
                allowExtendFlexmat = selectedFlexmatMachine.AllowExtend;
                
                let tempCreateDate = new Date(selectedFlexmatMachine.DateCreated);
                moduleDateCreated = `${tempCreateDate.getFullYear()}-${(tempCreateDate.getMonth() + 1).toString().padStart(2,0)}-${tempCreateDate.getDate()}`;
            }
        }

        return (
            <div className="content-pane-wrapper">
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <div>
                    <GridComponent id="customer-grid" ref={ref => this.customerGridRef = ref } dataSource={this.data} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.toolbarOptions}
                        editSettings={this.defaultEditSettings} selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both"
                        actionBegin={this.actionBegin.bind(this)} toolbarClick={this.clickHandler} allowReordering={true} allowResizing={true} actionComplete={this.onActionComplete.bind(this)}
                        columnDragStart={this.onColumnDragStart.bind(this)} created={this.onCreated.bind(this)} resizeStop={this.onResizeStop.bind(this)}>
                        <ColumnsDirective>
                            <ColumnDirective field="Id" headerText="Id" isPrimaryKey={true} />
                            <ColumnDirective field="CustomerNo" headerText="Kunde nr." width='100' isPrimaryKey={true} />
                            <ColumnDirective field="CompanyName" headerText="Kunde navn" width='100' /> 
                            <ColumnDirective field="PhoneNumber" headerText="Telefon nr." width='100' /> 
                            <ColumnDirective field="VatNo" headerText="Cvr. nr" width='100' /> 
                            <ColumnDirective field="Machines" headerText="FlexMat" width='100' />
                            <ColumnDirective field="CustomerGroupName" headerText="Kunde gruppe" width='100' />
                            <ColumnDirective field="DistributorName" headerText="Distributør" width='100' /> 
                            <ColumnDirective field="WholesalerName" headerText="Grossist" width='100' />
                            <ColumnDirective field="Account.AppAccess" headerText="Oilmat" width='100' template={this.oilmatSubscriptionTemplate} />
                            <ColumnDirective field="OilmatCustomer.McuCount" headerText="Oilmat MCU" width='100' textAlign="Right" />
                            <ColumnDirective field="Id" headerText="Åben Kunde" textAlign="Center" visible={this.isILXAdmin} width='110' template={this.editCustomerTemplate} />
                        </ColumnsDirective>
                        <Inject services={[Page, Sort, Filter, Selection, Toolbar, Edit, Search, Reorder, Resize]} />
                    </GridComponent>
                </div>
                <div>
                    <DialogComponent id="customer-dialog" isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.dialogInstance = dialog} 
                        target='body' visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
                    <DialogComponent isModal={true} buttons={this.confirmDeleteButtons} width='auto' ref={dialog => this.confirmDeleteDialog = dialog} target='body' visible={false} showCloseIcon={true}
                        header="Advarsel" cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
                </div>
                <div>
                    <DialogComponent id="customer-detail-dialog" isModal={true} buttons={this.customerDetailsButtons()} width='auto' ref={dialog => this.customerDetailDialog = dialog} 
                        target='body' visible={false} showCloseIcon={true} animationSettings={this.animationSettings} allowDragging={true} enableResize={true} close={() => this.setState({ currentTabSelected: 0, selectedFlexmatMachine: null })}>
                            <Tabs className="c-tab" selectedIndex={currentTabSelected} onSelect={index => this.onTabSelected(index)} disabledTabClassName="c-tabs__tab--disabled">
                                <TabList>
                                    <Tab className={`c-tabs__tab`} selectedClassName="c-tabs__tab--selected"><h2>Kunde Detaljer</h2></Tab>
                                    <Tab className={`c-tabs__tab ${data.OilmatCustomer ? '' : 'hidden'}`} disabled={!data.OilmatCustomer} selectedClassName="c-tabs__tab--selected"><h2>OilMat</h2></Tab>
                                    <Tab className={`c-tabs__tab ${data.FlexmatCustomer ? '' : 'hidden'}`} disabled={!data.FlexmatCustomer} selectedClassName="c-tabs__tab--selected"><h2>FlexMat</h2></Tab>
                                </TabList>
                                <TabPanel>
                                    <div className="form customer-detail-form">
                                        {/* <div className="form-title"><h2>Kunde Detaljer</h2></div> */}
                                        <div className="form-row">
                                            <div className="form-group form-col">
                                                <TextBoxComponent value={data.CustomerNo} placeholder="Konto Nr." name="CustomerNo" disabled={true} change={this.onChange} floatLabelType="Auto" />
                                            </div>
                                            <div className="form-group form-col">
                                                <TextBoxComponent value={data.CompanyName} placeholder="Firma navn*" name="CompanyName" change={this.onChange} floatLabelType="Auto" autocomplete='off' />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group form-col">
                                                <TextBoxComponent value={data.Street} placeholder="Adresse" name="Street" change={this.onChange} floatLabelType="Auto" autocomplete='off' />
                                            </div>
                                            <div className="form-group form-col">
                                                {/* <TextBoxComponent value={data.ZipNo} placeholder="Post nr" name="ZipNo" change={this.onChange.bind(this)} floatLabelType="Auto" /> */}
                                                <DropDownListComponent id="ddlPostalCodeId" ref={ref => this.ddlPostalCodeRef = ref} 
                                                    name="PostalCodeId"
                                                    value={data.PostalCodeId} 
                                                    dataSource={this.postalCodes} 
                                                    filtering={this.onPostalCodeFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchPostalCode} 
                                                    allowFiltering={true} placeholder="Post nr" 
                                                    fields={{ text: 'Value', value: 'Id' }} 
                                                    change={this.onPostalCodeChange} floatLabelType="Auto" locale="da" 
                                                    noRecordsTemplate={() => this.noRecordTemplate('PostalCodeNew')}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group form-col">
                                                <DropDownListComponent id="ddlCity" ref={ref => this.ddlCityRef = ref} 
                                                    name="CityId" value={data.CityId} 
                                                    dataSource={this.cities} 
                                                    filtering={this.onCityFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchCity}
                                                    allowFiltering={true} placeholder="By" fields={{ text: 'ShortName', value: 'Id' }} 
                                                    change={this.onCityChange} floatLabelType="Auto" locale="da" 
                                                    noRecordsTemplate={() => this.noRecordTemplate('CityNew')}
                                                />
                                            </div>
                                            <div className="form-group form-col">
                                                <DropDownListComponent id="ddlRegion" ref={ref => this.ddlRegionRef = ref} 
                                                    name="RegionId" value={data.RegionId} 
                                                    dataSource={this.regions} 
                                                    filtering={this.onRegionFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchRegion}
                                                    allowFiltering={true} placeholder="Region" fields={{ text: 'Name', value: 'Id' }} 
                                                    change={this.onRegionChange} floatLabelType="Auto" locale="da"
                                                    noRecordsTemplate={() => this.noRecordTemplate('RegionNew')}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group form-col">
                                                {/* <TextBoxComponent value={data.Country} placeholder="Land" name="Country" change={this.onChange} floatLabelType="Auto" /> */}
                                                <DropDownListComponent id="ddlCountry" ref={ref => this.ddlCountryRef = ref} name="CountryId" value={data.CountryId} dataSource={this.countries} filtering={this.onCountryFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchCountry}
                                                    allowFiltering={true} placeholder="Land" fields={{ text: 'Name', value: 'Id' }} change={this.onCountryChange} floatLabelType="Auto" locale="da" />
                                            </div>
                                            <div className="form-group form-col">
                                                <TextBoxComponent value={data.PhoneNumber} placeholder="Tlf . Nr." name="PhoneNumber" change={this.onChange} floatLabelType="Auto" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group form-col">
                                                <TextBoxComponent value={data.ContactMail} placeholder="Kontakt mail" name="ContactMail" change={this.onChange} floatLabelType="Auto" />
                                            </div>
                                            <div className="form-group form-col">
                                                <NumericTextBoxComponent showSpinButton={false} value={data.VatNo} placeholder="Cvr. Nr.*" format="###" name="VatNo" change={this.onCvrChange} floatLabelType="Auto" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group form-full-col">
                                                <DropDownListComponent id="ddlCustomerGroup" name="CustomerGroupId" value={data.CustomerGroupId} dataSource={this.customerGroups} locale="da"
                                                placeholder="Kunde Grupper*" fields={{ text: 'text', value: 'Id' }} change={this.onCustomerGroupChange} floatLabelType="Auto" />
                                            </div>
                                        </div>
                                        { isShowGrossist &&
                                            <div className="form-row">
                                                <div className="form-group form-full-col">
                                                    <DropDownListComponent id="ddlWholesaler" name="WholesalerId" ref={ref => this.wholesalerRef = ref} value={data.WholesalerId} dataSource={this.wholesalers} locale="da"
                                                    placeholder="Vælg grossist" fields={{ text: 'Name', value: 'Id' }} change={this.onWholesalerDropdownChange} floatLabelType="Auto" />
                                                </div>
                                            </div>                                       
                                        }
                                        { isShowDist && 
                                            <div className="form-row">
                                                <div className="form-group form-full-col">
                                                    <DropDownListComponent id="ddlDistributor" name="DistributorId" ref={ref => this.distributorRef = ref} value={data.DistributorId} dataSource={this.distributors} locale="da"
                                                    placeholder="Vælg Distributør" fields={{ text: 'Name', value: 'Id' }} change={this.onDistributorDropdownChange} floatLabelType="Auto" />
                                                </div>
                                            </div>
                                        }
                                        <div className='form-row'>
                                            <div className="form-group form-full-col">
                                                <CheckBoxComponent name="IsOilmatWholesaler" cssClass="control-left m-right-15" 
                                                    checked={data.IsOilmatWholesaler} change={this.onCheckChange} />
                                                <span>Is OilMat wholesaler?</span>
                                            </div>
                                        </div>
                                        { data.Id &&
                                        <div>
                                            <div className="form-title"><h2>Kontostatus</h2></div>
                                            <div className="form-row">
                                                <div className="form-group form-col">
                                                    <div className="field-bordered text-bold">
                                                        {data.Active ? Localization.Customer.Active : Localization.Customer.Inactive}
                                                    </div>
                                                </div>
                                                <div className="form-group form-col">
                                                    <button className="e-btn e-primary e-flat float-right" type="button" onClick={this.toggleActive}>{data.Active ? Localization.Customer.Deactivate : Localization.Customer.Activate}</button>
                                                </div>
                                            </div>
                                        </div>}
                                        <div className="form-title"><h2>Konto Detaljer</h2></div>
                                        <div className="form-row">
                                            <div className="form-group form-col">
                                                <TextBoxComponent value={data.IntUserName} placeholder="Brugernavn*" name="IntUserName" change={this.onChange} floatLabelType="Auto" />
                                            </div>
                                            <div className="form-group form-col">
                                                <TextBoxComponent value={data.FirstName} placeholder="Fornavn*" name="FirstName" change={this.onChange} floatLabelType="Auto" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group form-col">
                                                <TextBoxComponent value={data.MiddleName} placeholder="Mellem navn" name="MiddleName" change={this.onChange} floatLabelType="Auto" />
                                            </div>
                                            <div className="form-group form-col">
                                                <TextBoxComponent value={data.LastName} placeholder="Efternavn*" name="LastName" change={this.onChange} floatLabelType="Auto" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group form-full-col">
                                                <TextBoxComponent value={data.Email} placeholder="Mail for log in og interne beskeder*" name="Email" change={this.onChange} floatLabelType="Auto" />
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="form mt-25">
                                        <div className="form-row">
                                            <div className="form-group form-full-col">
                                                <TextBoxComponent value={mcusCount} placeholder="Antal OilMat App connected" name="Mcus" enabled={false} floatLabelType="Auto" />
                                            </div>
                                        </div>
                                        <div className="mt-25 mb-25">
                                            <GridComponent id="oilmat-grid" ref={ref => this.oilmatGridRef = ref } dataSource={this.oilmatMcu} locale="da" gridLines="Both"
                                                //allowPaging={true} pageSettings={this.secondaryGridPageSettings} 
                                                selectionSettings={this.selectionSettings}>
                                                <ColumnsDirective>
                                                    <ColumnDirective field="DeviceFamily" headerText="Enhed" width='100' isPrimaryKey={true} />
                                                    <ColumnDirective field="SerialNumber" headerText="Serie nummer" width='100' isPrimaryKey={true} />
                                                </ColumnsDirective>
                                            </GridComponent>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group form-full-col">
                                                <TextBoxComponent value={createDate} placeholder="Oprettet" name="CreationDate" enabled={false} floatLabelType="Auto" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group form-full-col">
                                                <TextBoxComponent value={oilmatSubscriptionEndDate} placeholder="Skal fornyes næste gang:" name="SubscriptionOilmatEndDate" enabled={false} floatLabelType="Auto" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group form-full-col">
                                            <label>Adgang til App</label>
                                                <ul className="list-no-style">
                                                    <li><RadioButtonComponent label='Aktiv' checked={data.AppAccess === true} value={1} name="AppAccess" change={this.updateAppAccess} /></li>
                                                    <li><RadioButtonComponent label='Deaktiver' checked={!data.AppAccess === true} value={2} name="AppAccess" change={this.updateAppAccess} /></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* <div className="form-row">
                                            <div className="form-group form-full-col text-center btn-extend">
                                                <ButtonComponent onClick={this.onExtendSbuscriptionClick} isPrimary disabled={!allowExtend}>Forny 12 måneder</ButtonComponent>
                                            </div>
                                        </div> */}
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="form-row">
                                        <div className="form-group form-full-col">
                                            <TextBoxComponent value={machinesCount} placeholder="Antal FlexMat App connected" name="Machines" enabled={false} floatLabelType="Auto" />
                                        </div>
                                    </div>
                                    <div className="mt-25 mb-25">
                                        <GridComponent id="flexmat-grid" ref={ref => this.flexmatGridRef = ref} dataSource={this.flexmatMachines} locale="da" gridLines="Both"
                                            selectionSettings={this.selectionSettings} rowSelected={this.onFlexmatRowSelected}>
                                            <ColumnsDirective>
                                                <ColumnDirective field="CustomName" headerText="Enhed" width='100' isPrimaryKey={true} template={this.flexmatMachineName} />
                                                <ColumnDirective field="EditableSerialNumber" headerText="Serie nummer" width='100' isPrimaryKey={true} />
                                            </ColumnsDirective>
                                        </GridComponent>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group form-full-col">
                                            <TextBoxComponent value={moduleDateCreated} placeholder="Oprettet" name="CreationDate" enabled={false} floatLabelType="Auto" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group form-full-col">
                                            <TextBoxComponent value={flexmatSubscriptionEndDate} placeholder="Skal fornyes næste gang:" name="SubscriptionEndDate" enabled={false} floatLabelType="Auto" />
                                        </div>
                                    </div>
                                    {/* <div className="form-row">
                                        <div className="form-group form-full-col btn-extend text-center">
                                            <ButtonComponent onClick={this.onExtendSbuscriptionClick} isPrimary disabled={!allowExtend}>Forny 12 måneder</ButtonComponent>
                                        </div>
                                    </div> */}
                                </TabPanel>
                            </Tabs>
                    </DialogComponent>
                </div>
            </div>
        )
    }
}

Customers.contextType = AppContext;

export default Customers;