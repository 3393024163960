import httpClientOilmat from '../utilities/HttpClientOilmat';

const API = {
    getWholesaler: "/customer?customerNumber={0}",
    getWholesalerByCountry: "/customer/wholesalers/byCountry?countryId={0}",
    postOilmatRegistration: "/customer"
}

class OilmatRegistrationService {
    async GetWholesaler(id, cancelToken) {
        return await httpClientOilmat.Get(API.getWholesaler.replace("{0}", id), null, cancelToken);
    }

    async GetWholeSalerByCountry(id, cancelToken) {
        return await httpClientOilmat.Get(API.getWholesalerByCountry.replace("{0}", id), null, cancelToken);
    }

    async Register(data, cancelToken) {
        const payload = {
            CompanyName: data.CompanyName,
            Street: data.Street,
            PostalCodeId: data.PostalCodeId,
            VatNo: data.VatNo !== null ? data.VatNo : '-',
            WholesalerId: data.WholesalerId,
            ContactMail: data.ContactMail,
            InternalAccountId: data.InternalAccountId,
            DeliveryAddress: {
                PhoneNumber: data.DeliveryAddress.PhoneNumber,
                DeliveryPostalCodeId: data.DeliveryAddress.DeliveryPostalCodeId,
                Email: data.DeliveryAddress.Email,
                DeliveryStreet: data.DeliveryAddress.DeliveryStreet,
                DeliveryPostalCodeNew: data.DeliveryAddress.DeliveryPostalCodeNew,
                CityId: data.DeliveryAddress.CityId,
                CityNew: data.DeliveryAddress.CityNew,
                RegionId: data.DeliveryAddress.RegionId,
                RegionNew: data.DeliveryAddress.RegionNew,
                CountryId: data.DeliveryAddress.CountryId
            },
            Account: {
                Username: `${data.ContactMail}_internalonly`,
                FirstName: data.User.FirstName,
                MiddleName: data.User.MiddleName,
                LastName: data.User.LastName,
                Email: `${data.ContactMail}_internalonly`,
                PhoneNumber: data.PhoneNumber,
                Initials: data.User.Initials
            },
            User: {
                Username: data.User.Username,
                FirstName: data.User.FirstName,
                MiddleName: data.User.MiddleName,
                LastName: data.User.LastName,
                Email: data.User.Username,
                PhoneNumber: data.User.PhoneNumber,
                Initials: data.User.Initials
            },
            Mcu: {
                ProductType: 0
            },
            PostalCodeNew: data.PostalCodeNew,
            CityNew: data.CityNew,
            RegionNew: data.RegionNew,
            CityId: data.CityId,
            RegionId: data.RegionId,
            CountryId: data.CountryId
        };
        console.log(payload);
        
        return await httpClientOilmat.Post(API.postOilmatRegistration, payload, cancelToken);
    }
}

const oilmatRegistrationService = new OilmatRegistrationService();
export default oilmatRegistrationService;